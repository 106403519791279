<template>
    <div class="container-xxl flex-grow-1 container-p-y">

        <div class="row">
            <div class="col-12 col-md-9">
                <div class="card">
                    <div class="card-header">
                        <h5>
                            {{ $t('client.header.title') }}
                        </h5>
                        <div class="card-header-right">
                            <router-link to="/create" class="btn btn-outline-primary">{{ $t('client.button.create')
                            }}</router-link>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table table-index">
                            <tr>
                                <th>
                                    {{ $t('client.comment_name') }}
                                </th>
                                <th>
                                    {{ $t('client.client_type') }}
                                </th>
                                <th>
                                    {{ $t('client.client_wojewodztwa') }}
                                </th>
                                <th>
                                    {{ $t('client.client_want_to') }}
                                </th>
                                <th>
                                    {{ $t('client.client_stage') }}
                                </th>
                                <th></th>
                            </tr>
                            <tr v-for="item in items" :key="item">
                                <td>{{ item.client_name }}</td>
                                <td>{{ item.client_type }}</td>
                                <td></td>
                                <td><span class="me-2 badge badge-primary" v-for="i in item.g_client_want_to" :key="i">{{ i
                                }}</span></td>
                                <td>{{ item.client_stage }}</td>
                                <td>
                                    <button @click="clickComment(item)"
                                        class="btn btn-icon-sm btn-outline-secondary me-1 off_tooltip_off" title="Edytuj"><i
                                            class="material-icons-outlined">comment</i></button>

                                    <button @click="clickEdit(item)"
                                        class="btn btn-icon-sm btn-outline-secondary me-1 off_tooltip_off" title="Edytuj"><i
                                            class="material-icons-outlined">create</i></button>
                                    <delete_confirm v-if="item._links.delete" text='Usunąć klienta'
                                        :url="item._links.delete.href" @confirm="onDelete" @error="onDelete">
                                    </delete_confirm>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <nav>
                        <ul class="pagination justify-content-center">
                            <li @click.prevent="clickPage(1)"><a class="page-link">
                                    {{ $t('client.search.first') }}</a></li>
                            <li class="page-item" :class="{ active: search.page == index }" :key="index"
                                @click.prevent="clickPage(index)" v-for="index in search.pages">
                                <a class="page-link" href="#"
                                    v-if="search.page != index && ((index > Number(search.page) - 5 && index <= Number(search.page)) || (index < Number(search.page) + 5 && index >= Number(search.page)))">
                                    {{ index }}
                                </a>
                                <span v-if="search.page == index" class="page-link">
                                    {{ index }}
                                </span>
                            </li>
                            <li @click.prevent="clickPage(search.pages)"><a class="page-link">
                                    {{ $t('client.search.last') }}</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from '../../api/axios';
import api from '../../api/api';
export default {
    name: 'comment',
    template: '#comment-template',
    components: {},
    data: function () {
        return {
            BASE_URL: api.getBaseUrl(),
            isLoading: true,
            items: [],
            slownik_client_type: this.$store.state.slownik_client_type,
            slownik_client_want_to: this.$store.state.slownik_client_want_to,
            slownik_wojewodztwo: this.$store.state.slownik_wojewodztwo,
            search: {
                page: 1,
                limit: 100,
                pages: 1,
                client_id: '',
            },
        }
    },
    methods: {
        lang(x) { return x },
        convertWantTo(item) {
            var vm = this;
            var ar = item.client_want_to.split('|');
            console.log(ar);
            vm.slownik_client_want_to.forEach(i => {
                // item.g_client_want_to[i.value] = i.name
                ar.forEach(y => {
                    if (y == i.value) item.g_client_want_to.push(i.name);
                })
            })
        },
        clickPage(page) {
            this.search.page = page;
            this.getPosts();
        },
        onDelete() {
            this.getPosts();
        },
        clickComment: function (item) {
            this.$router.push('/comment/' + item.client_id);
        },
        clickEdit: function (item) {
            this.$router.push('/edit/' + item.client_id);
        },
        getPosts: function () {
            let vm = this;
            vm.isLoading = true;
            this.search.client_id = this.client_id;
            axios.get(this.BASE_URL + 'api/v1/client/comment', {
                params: this.search
            }).then(response => {
                response.data.data.forEach(item => {
                    item.g_client_want_to = [];
                    vm.convertWantTo(item);
                })
                vm.items = response.data.data;
                vm.search.pages = response.data.pages;
                vm.isLoading = false;
            });
        },
        submitSearchForm: function () {
            this.search.page = 1;
            this.getPosts();
        },
        resetSearchForm: function () {
            for (const property in this.search) {
                this.search[property] = '';
            }
            this.getPosts();
        }
    },
    created() {
        this.client_id = this.$route.params.client_id;
        this.search.client_group = this.$route.params.slownik ? this.$route.params.slownik : '';
        this.getPosts();
    }
}
</script>
